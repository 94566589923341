.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gauge {
  background: #e7e7e7;
  border: 1px solid #CCD4DE;
  position: relative;
  overflow: hidden;
  -webkit-transform-style: flat;
  -webkit-transform: translateZ(0px);
}

.gauge-center {
  width: 75%;
  height: 74%;
  background: #fff;
  border: 1px solid #CCD4DE;
  border-bottom: 0px;
  position: absolute;
  right: 13%;
  bottom: 0px;
  color: #fff;
  text-align: center;
  z-index: 15;
}
.gauge-center .label, .gauge-center .number {display:block; width: 100%; text-align: center; border:0!important;}
.gauge-center .label {font-size:0.75em; opacity:0.6; margin:1.1em 0 0.3em 0;}
.gauge-center .number {font-size:1.2em;}

.low-range {
  position: absolute;
  z-index: 100;
  color: #000;
  right: 75%;
  top: -5px;
}
.high-range {
  position: absolute;
  z-index: 100;
  color: #000;
  left: 75%;
  top: -5px;
}

.needle {
  width: 40px;
  height: 2px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 100%;
  border-top-right-radius: 0px;
  position: absolute;
  bottom: 1px;
  left: 25px;
  transform-origin: 100% 4px;
  animation: speed 5s infinite;
  z-index: 110
}
.needle2 {
  width: 32px;
  height: 2px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 100%;
  border-top-right-radius: 0px;
  position: absolute;
  bottom: 1px;
  left: 34px;
  transform-origin: 100% 4px;
  animation: speed 5s infinite;
  z-index: 100
}

.needle-pointer {
  width: 10px;
  height: 10px;
  background-color: #02569D;
  position: absolute;
  bottom: -6px;
  left: 63px;
  z-index: 100;
  border-radius: 50%;
  z-index: 101
}

.slice-colors .st {
  position: absolute;
  bottom: 0;
}
.slice-colors .st.slice-item:nth-child(1) {
  background-color: #EB8916;
  transform: rotate(240deg);
  z-index: 3;
}

.slice-colors .st.slice-item:nth-child(2) {
  background-color: #15C63C;
  transform: rotate(300deg);
  z-index: 2;
}
.slice-colors .st.slice-item:nth-child(3) {
  background-color: #D74141;
  transform: rotate(360deg);
  z-index: 1;
}



/* meter */
.slice-colors .st.slice-item.meter:nth-child(1) {
  background-color: #FFE6E3 !important;
}
.slice-colors .st.slice-item.meter:nth-child(2) {
  background-color: #FFF6E5;
}
.slice-colors .st.slice-item.meter:nth-child(3) {
  background-color: #DCFCE7;
}

.slice-colors .slider {
  position: absolute;
  bottom: 0;
  z-index: 10;
}

.gauge-value {
  color: #000;
  width: 75%;
  position: absolute;
  right: 13%;
  bottom: 0px;
  text-align: center;
  z-index: 5;
}

.breakpoint-marker1 {
  position: absolute;
  z-index: 200;
  border-left: 1px solid #CCD4DE;
  transform: rotate(-30deg);
  right: 71%;
  top: 6%;
}
.breakpoint-marker2 {
  position: absolute;
  z-index: 200;
  border-left: 1px solid #CCD4DE;
  transform: rotate(30deg);
  left: 72%;
  top: 6%;
}