* {
  box-sizing: border-box;
  text-decoration: none;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E9EDF2;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTimelineItem-root:before {
  display: none;
}

/* Custom scrollbar styling */
*::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* height of the scrollbar */
}

*::-webkit-scrollbar-track {
  background: #f0f5fa;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #BFCAD9;
  border-radius: 10px;
  border: 2px solid #f0f5fa;
}

*::-webkit-scrollbar-thumb:hover {
  background-color:  #8692A3;
  cursor: pointer;
}
*::-webkit-scrollbar-thumb:active {
  background-color:  #636F7F;
  cursor: pointer;
}

@keyframes wave {

  0%,
  50% {
    transform: scaleY(1);
  }

  25% {
    transform: scaleY(15);
    background-color: lightblue;
  }
}